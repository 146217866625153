<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>基础配置</a-breadcrumb-item>
              <a-breadcrumb-item>模块管理</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="Id == ''" class="Enter-top-right">
          <a-input-group style="width: 300px" compact>
            <a-select style="width: 90px" v-model="value" default-value="name">
              <a-select-option value="name"> 模块名 </a-select-option>
              <a-select-option value="path"> 路径 </a-select-option>
              <a-select-option value="permissions">
                模块包含子权限
              </a-select-option>
              <a-select-option value="state"> 状态 </a-select-option>
            </a-select>
            <a-select
              v-if="value == 'state'"
              @change="onSearch"
              style="width: 210px"
            >
              <a-select-option value="0"> 启用 </a-select-option>
              <a-select-option value="1"> 禁用 </a-select-option>
            </a-select>
            <a-input-search
              v-else
              placeholder="请输入搜索内容"
              style="width: 210px"
              v-model="key"
              @search="onSearch"
            />
          </a-input-group>
          <a-button
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增模块
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <div v-if="!visible">
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="modulelist"
                :rowClassName="rowClassName"
              >
                <span slot="status" slot-scope="text, record">
                  <a-switch
                    :checked="text == 0 ? true : false"
                    default-checked
                    @change="onchangestate(record)"
                  />
                </span>
                <template slot="action" slot-scope="text, record">
                  <a @click="onEdit(record)">编辑</a>
                  <div
                    v-if="record.superiorId == null"
                    style="display: inline-block"
                  >
                    &nbsp;|&nbsp;
                    <a @click="onaddChild(record)">添加子模块</a>
                  </div>
                </template>
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="2"
                :total="total"
                @change="onpagesize"
              />
            </div>
            <div v-else>
              <a-form-model
                ref="ruleForm"
                :rules="rules"
                :model="form"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item ref="name" prop="name" label="模块名称">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.name"
                  />
                </a-form-model-item>
                <a-form-model-item ref="path" prop="path" label="模块路径">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.path"
                  />
                </a-form-model-item>
                <a-form-model-item prop="permissions" label="模块包含的子权限">
                  <a-select
                    mode="tags"
                    v-model="form.permissions"
                    :token-separators="[]"
                    @change="handleChange"
                  >
                    <a-select-option value="新增"> 新增 </a-select-option>
                    <a-select-option value="修改"> 修改 </a-select-option>
                    <a-select-option value="删除"> 删除 </a-select-option>
                    <a-select-option value="查询"> 查询 </a-select-option>
                    <a-select-option value=" 查询下级">
                      查询下级
                    </a-select-option>
                    <a-select-option value="查询同级">
                      查询同级
                    </a-select-option>
                    <a-select-option value="查询全部">
                      查询全部
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="图标">
                  <a-input v-model="form.icon" />
                  <a-tooltip>
                    <template slot="title"> 点击查看图标库 </template>
                    <a-icon
                      @click="onicon"
                      class="icon-url"
                      type="exclamation-circle"
                    />
                  </a-tooltip>
                </a-form-model-item>
                <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
                  <a-button type="primary" @click="onSubmit"> 提交 </a-button>
                  <a-button @click="onClose" style="margin-left: 10px">
                    取消
                  </a-button>
                </a-form-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '路径',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
  },
  {
    title: '模块包含子权限',
    dataIndex: 'permissions',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      visible: false,
      placement: 'right',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },

      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      modulelist: [],
      value: 'name',
      key: '',
      selectedRowKey: [],
      Id: '',
      form: {
        id: '',
        superiorId: '',
        permissions: '新增',
        path: '',
        name: '',
        type: 0,
        icon: '',
        state: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '模块名称长度为2-16',
            trigger: 'blur',
            min: 2,
            max: 16,
          },
        ],
        path: [
          {
            required: true,
            message: '模块路径不能为空',
            trigger: 'blur',
          },
        ],
        permissions: [
          {
            required: true,
            message: '模块包含子权限不能为空',
            trigger: 'blur',
          },
        ],
        icon: [
          {
            required: true,
            message: '图标不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (value == 'state') {
        this.params[this.value].op = '='
      }
      this.key = ''
      this.getModule()
    },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form.icon = ''
      this.form.superiorId = ''
      this.form.permissions = '新增'
      this.form.path = ''
      this.form.name = ''
      this.form.type = '0'
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          this.form.permissions = JSON.stringify(this.form.permissions)
          if (this.Id == '') {
            this.AddModule(this.form)
          } else if (this.Id != '') {
            this.putModule(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    // 删除
    onDetele() {
      const that = this
      this.$confirm({
        title: '确认要删除该模块么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteModule()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteModule() {
      try {
        const res = await http.deleteModule(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    // 新增子模块
    onaddChild(item) {
      this.visible = true
      this.form.superiorId = item.id
    },
    // 编辑
    onEdit(item) {
      //   console.log(item);
      this.Id = item.id
      this.form.id = item.id
      //   console.log(this.form.id);
      this.visible = true
      this.title = '编辑'
      this.getModuleId(item.id)
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.stateModule(e.id, this.form.state)
    },
    async putModule(form) {
      try {
        const res = await http.putModule(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onicon() {
      window.open('https://antdv.com/components/icon-cn/')
    },
    onpagesize(e) {
      this.params.current = e
      this.getModule()
      // console.log(this.params.current);
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
    },
    async getModule() {
      try {
        const res = await http.getModule(this.params)
        const { success, data } = res.data
        if (success) {
          this.modulelist = data.modules.records
          this.total = data.modules.total
          //   console.log(data.modules);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getModuleId(id) {
      try {
        const res = await http.getModuleId(id)
        // console.log(res);
        const { success, data } = res.data
        if (success) {
          this.form.name = data.module.name
          this.form.icon = data.module.icon
          this.form.state = data.module.state
          this.form.superiorId = data.module.superiorId
          this.form.path = data.module.path
          this.form.permissions = JSON.parse(data.module.permissions)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 新增
    async AddModule(form) {
      try {
        const res = await http.AddModule(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async stateModule(id, state) {
      try {
        const res = await http.stateModule(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getModule()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getModule()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
